/* Import font from font folder */
@font-face {
  font-family: 'Adolphus Serif';
  src: url('/public/fonts/Adolphus Serif.ttf') format('truetype');
}



body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2 {
  letter-spacing: 0.04rem;
}

p {
  font-size: 1.2rem;
  letter-spacing: 0.03rem;
  line-height: 1.6;
}

.popular-card {
  background: linear-gradient(135deg, #a5c9ff, #e0c3fc);
}

.popular-card .badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.fancy-text {
  font-family: 'Adolphus Serif', serif;
}

.vh-80 {
  /* Viewport height of 80 if not mobile */
  @media screen and (min-width: 768px) {
    height: 80vh;
  }
}

.footer-logo {
  width: 20%;
}

.card-typography {
  letter-spacing: 0.03rem;
  /* subtle outline for legibility */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  font-weight: 400;
}

.smaller-font {
  font-size: 1rem;
}

.quotes-img {
  width: 10%;
  max-width: 40px;
}

.active {
  font-weight: 700 !important;
}

.promotion-image {
  max-height: 200px;
}

@media (max-width: 576px) {
  .promotion-image {
      max-height: 80px;
  }
}

.footer-link:hover {
  transition: 0.3s ease;
  color: #AABCFE !important;
}

.page-heading {
  position: relative;
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;

  /* lower height on mobile */
  @media screen and (max-width: 768px) {
    height: 20vh;
  }
}

.page-heading h1 {
  font-size: 4rem;
  letter-spacing: 0.5rem;

  @media screen and (max-width: 1200px) {
    font-size: 10vw;
    letter-spacing: 0.1rem;
}
}

.grow-on-hover {
  transition: all 0.3s ease;
}

.grow-on-hover:hover {
  transform: scale(1.02);
}

.cursor-default {
  cursor: default;
}

.chip-font {
  font-size: 1.2rem;

  /* on medium and below, make font smaller */
  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
}

.form-floating .form-control {
  background-color: #EEF2FF;
  border-radius: 1rem;
}

.form-floating .form-control:focus {
  background-color: #EEF2FF;
  border-color: #AABCFE;
}

.form-floating label {
  color: #AABCFE !important;
  font-weight: 400;
}

.form-floating label::after {
  background-color: #ffffff00 !important;
}

.form-floating textarea {
  height: 10rem !important;
}

.navbar-logo {
  width: 19%;
}

/* navbar title should be smaller on mobile */
.navbar-title {
  font-size: 1.5rem;

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    border: none !important;
    box-shadow: none !important;
    z-index: 10000;
}

.glassmorphism {
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  background: linear-gradient(rgba(255, 255, 255, 0.4), transparent);
}

.slick-slide {
  transition: all 0.3s ease;
  transform: scale(0.9);
  opacity: 0.5;
}

.slick-slide.slick-center {
  opacity: 1;

  @media screen and (min-width: 1100px) {
    transform: scale(1.03);
  }
}

.slick-next:before,
.slick-prev:before {
  color: #AABCFE !important;
  font-size: 2rem !important;
}

.small-button-font {
  font-size: 1rem !important;
}

.negative-top {
  margin-top: -0.7rem;
}