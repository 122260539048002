.hero {
    position: relative;
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    z-index: -1;
    opacity: 0.5;
}

.hero-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    /* 400px and below remove flex */
    @media screen and (max-width: 400px) {
        flex-direction: column;
        width: 90%;
        bottom: 2%;
    }
}

.hero-heading {
    font-size: 9rem;
    letter-spacing: 0.1em;

    @media screen and (max-width: 1200px) {
        font-size: 10vw;
    }
}

.hero-buttons .btn {
    font-size: 1.2rem;
    padding: 10px 20px;
}

.bg-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -5;
}