@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
// Override default variables before the import
$primary: #AABCFE;
$secondary: #EEF2FF;
$dark: #323232;
$danger: #cf0a31;
$success: #25D366;
$info: 	#1877F2;

$btn-border-radius: 0.7rem;

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1600px
);

// Add breakpoints for rounded class


// Set font family
$font-family-base: 'Montserrat', sans-serif;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';